
import * as cloudflareRuntime$15nfDH5yVG from '/opt/buildhome/repo/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.3_ioredis@5.4.2_magicast@0.3.5_rollup@4.34.2/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'
import * as noneRuntime$T3r8cQgUBs from '/opt/buildhome/repo/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.3_ioredis@5.4.2_magicast@0.3.5_rollup@4.34.2/node_modules/@nuxt/image/dist/runtime/providers/none'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "none",
  "domains": [
    "cms.grapelaw.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "webp"
  ],
  "quality": 85
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$15nfDH5yVG, defaults: {"baseURL":"https://v2.grapelaw.com"} },
  ['none']: { provider: noneRuntime$T3r8cQgUBs, defaults: {} }
}
        